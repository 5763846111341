export type TemplateType =
  | '4th-of-july-promo'
  | '4th-of-july-coupon-promo'
  | 'fathers-day-promo'
  | 'back-to-school-announcement'
  | 'back-to-school-reminder'
  | 'back-to-school-coupon'
  | 'back-to-school-shop'
  | 'back-to-school-customs'
  | 'back-to-school-last-call'
  | 'product-promo'
  | 'event-promo'
  | 'presale-promo'
  | 'coupon-promo'
  | 'ticketed-event-promo';

export interface SmsTemplate {
  eventName?: string;
  eventTag?: string;
  pageTitle: string;
  pageTitleMobile?: string;
  step1TitleUnselected: string;
  step1TitleSelectedPrefix?: string;
  message: string;
  postSendModalTitlePrefix: string;
  trackEventName: string;
  utmCampaign?: string;
  isCoupon: boolean;
  showCustomProductsOnly: boolean;
  showPresales: boolean;
  showTicketedEvents?: boolean;
}

export const smsTemplates = {
  '4th-of-july-promo': {
    eventName: '4th of July',
    eventTag: '4thOfJuly',
    pageTitle: '4th of July Promo Text',
    pageTitleMobile: '4th of July',
    step1TitleUnselected: 'Product or Presale to Promote',
    message:
      "Craving gourmet July 4th delights? Don't miss out on these exclusive flavors - place your order while supplies last! 🎇",
    postSendModalTitlePrefix: '4th of July Promo Text',
    trackEventName: '4th of July Promo',
    utmCampaign: '4th_of_july_announcement',
    isCoupon: false,
    showCustomProductsOnly: false,
    showPresales: true,
  },
  '4th-of-july-coupon-promo': {
    eventName: '4th of July',
    eventTag: '4thOfJuly',
    pageTitle: '4th of July Coupon Promo Text',
    pageTitleMobile: '4th of July',
    step1TitleUnselected: '',
    message: 'Need something for your 4th of July party? Shop now to save.',
    postSendModalTitlePrefix: '4th of July Coupon Promo Text',
    trackEventName: '4th of July Coupon Promo',
    utmCampaign: '4th_of_july_announcement',
    isCoupon: true,
    showCustomProductsOnly: false,
    showPresales: false,
  },
  'fathers-day-promo': {
    eventName: "Father's Day",
    eventTag: 'FathersDay',
    pageTitle: "Father's Day Promo Text",
    pageTitleMobile: "Father's Day",
    step1TitleUnselected: 'Product or Presale to Promote',
    message:
      'Have you shopped for Dad yet? You’re in luck! Our Father’s Day treats are one size fits all. ⛳️ Order while supplies last:',
    postSendModalTitlePrefix: "Father's Day Promo Text",
    trackEventName: "Father's Day Promo",
    utmCampaign: 'fathers_day_announcement',
    isCoupon: false,
    showCustomProductsOnly: false,
    showPresales: true,
  },
  'back-to-school-announcement': {
    eventName: "Back to School",
    eventTag: 'BackToSchool',
    pageTitle: "Back-to-School Announcement",
    pageTitleMobile: "Back-to-School",
    step1TitleUnselected: 'Product or Presale to Promote',
    message:
      'Back-to-school nights, parent-teacher conferences, oh my! Don’t show up empty handed - place your order today!',
    postSendModalTitlePrefix: "Back-to-School SMS",
    trackEventName: "Back-to-School Announcement",
    utmCampaign: 'backtoschool_sms',
    isCoupon: false,
    showCustomProductsOnly: false,
    showPresales: true,
  },
  'back-to-school-reminder': {
    eventName: "Back to School",
    eventTag: 'BackToSchool',
    pageTitle: "Back-to-School Reminder",
    pageTitleMobile: "Back-to-School",
    step1TitleUnselected: 'Product or Presale to Promote',
    message:
      'The clock is ticking! Place your order while products are still available:',
    postSendModalTitlePrefix: "Back-to-School SMS",
    trackEventName: "Back-to-School Reminder",
    utmCampaign: 'backtoschool_sms',
    isCoupon: false,
    showCustomProductsOnly: false,
    showPresales: true,
  },
  'back-to-school-coupon': {
    eventName: "Back to School",
    eventTag: 'BackToSchool',
    pageTitle: "Back-to-School Coupon",
    pageTitleMobile: "Back-to-School",
    step1TitleUnselected: '',
    message:
      'Need something for your kiddo’s classroom party? Shop now to save!',
    postSendModalTitlePrefix: "Back-to-School SMS",
    trackEventName: "Back-to-School Coupon",
    utmCampaign: 'backtoschool_sms',
    isCoupon: true,
    showCustomProductsOnly: false,
    showPresales: false,
  },
  'back-to-school-shop': {
    eventName: "Back to School",
    eventTag: 'BackToSchool',
    pageTitle: "Back-to-School Shop",
    pageTitleMobile: "Back-to-School",
    step1TitleUnselected: 'Product or Presale to Promote',
    message:
      'Searching for teacher appreciation gifts? Make a delicious statement with artisanal goodies. Order now!',
    postSendModalTitlePrefix: "Back-to-School SMS",
    trackEventName: "Back-to-School Shop",
    utmCampaign: 'backtoschool_sms',
    isCoupon: false,
    showCustomProductsOnly: false,
    showPresales: true,
  },
  'back-to-school-customs': {
    eventName: "Back to School",
    eventTag: 'BackToSchool',
    pageTitle: "Back-to-School Custom Order",
    pageTitleMobile: "Back-to-School",
    step1TitleUnselected: 'Product to Promote',
    message:
      'Want to showcase your school spirit? Represent school colors? Request a custom order for back-to-school today!',
    postSendModalTitlePrefix: "Back-to-School SMS",
    trackEventName: "Back-to-School Customs",
    utmCampaign: 'backtoschool_sms',
    isCoupon: false,
    showCustomProductsOnly: true,
    showPresales: false,
  },
  'back-to-school-last-call': {
    eventName: "Back to School",
    eventTag: 'BackToSchool',
    pageTitle: "Back-to-School Last Call",
    pageTitleMobile: "Back-to-School",
    step1TitleUnselected: 'Product or Presale to Promote',
    message:
      'Shop back-to-school offerings before the last bell rings!',
    postSendModalTitlePrefix: "Back-to-School SMS",
    trackEventName: "Back-to-School Last Call",
    utmCampaign: 'backtoschool_sms',
    isCoupon: false,
    showCustomProductsOnly: false,
    showPresales: true,
  },
  'product-promo': {
    pageTitle: 'Product Promo Text',
    pageTitleMobile: 'Product Promo',
    step1TitleUnselected: 'Product to Promote',
    message: '🌟 Don’t miss out! 🍰 These are selling fast! Order yours now:',
    postSendModalTitlePrefix: 'Product Promo Text',
    trackEventName: 'Product Promo',
    utmCampaign: 'product_announcement',
    isCoupon: false,
    showCustomProductsOnly: false,
    showPresales: false,
  },
  'coupon-promo': {
    pageTitle: 'Coupon Promo Text',
    pageTitleMobile: 'Coupon Promo',
    step1TitleUnselected: '',
    message: '💕 Just for you!',
    postSendModalTitlePrefix: 'Coupon Promo Text',
    trackEventName: 'Coupon Promo',
    utmCampaign: 'coupon_announcement',
    isCoupon: true,
    showCustomProductsOnly: false,
    showPresales: false,
  },
  'event-promo': {
    pageTitle: 'Event Promo Text',
    pageTitleMobile: 'Event Promo',
    step1TitleUnselected: 'Product or Presale to Promote',
    message: '👋 Will we see you there? 🍰 Check out where we’ll be in person next and shop early:',
    postSendModalTitlePrefix: 'Event Promo Text',
    trackEventName: 'Event Promo',
    utmCampaign: 'event_announcement',
    isCoupon: false,
    showCustomProductsOnly: false,
    showPresales: true,
  },
  'presale-promo': {
    pageTitle: 'Presale Promo Text',
    pageTitleMobile: 'Presale Promo',
    step1TitleUnselected: 'Presale to Promote',
    message: '⏳ Available for a limited time! 🍰 Shop our latest presale before it ends. Shop now:',
    postSendModalTitlePrefix: 'Presale Promo Text',
    trackEventName: 'Presale Promo',
    utmCampaign: 'presale_announcement',
    isCoupon: false,
    showCustomProductsOnly: false,
    showPresales: true,
  },
  'ticketed-event-promo': {
    pageTitle: 'Ticketed Event Promo',
    pageTitleMobile: 'Ticketed Event',
    step1TitleUnselected: 'Event to Promote',
    message: 'Join us for our upcoming event! Grab your tickets now before they sell out.',
    postSendModalTitlePrefix: 'Event Promo Text',
    trackEventName: 'Ticketed Event Promo',
    utmCampaign: 'ticketed_event_promo',
    isCoupon: false,
    showCustomProductsOnly: false,
    showPresales: false,
    showTicketedEvents: true,
  },
};
