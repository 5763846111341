import { Theme } from '@material-ui/core';
import { Shop } from '@castiron/domain';
import { defaultTimeZone } from '@castiron/utils';
import { specialRepository } from '../../../domain';

export const getSingleSendEmails = async (theme: Theme, isPresalesEnabled: boolean, activeEventTags: string[]) => {
  //in order that they should display in modal
  const templates = [
    {
      icon: '💬',
      iconBackgroundColor: theme.branding.v2.gray[50],
      pillText: 'New!',
      name: 'Sign Up for SMS Email',
      subheader: 'Encourage email subscribers to opt in to SMS.',
      route: '/marketing/single-send-email?type=sms-signup',
      event: 'SMS Signup Email Created',
      category: 'Basic',
    },
    {
      icon: '🍪',
      iconBackgroundColor: theme.branding.v2.gray[50],
      name: 'Product Announcement Email',
      subheader: 'Notify customers about a new product.',
      route: '/marketing/single-send-email?type=new-product',
      category: 'Basic',
    },
    {
      icon: '🗓️',
      iconBackgroundColor: theme.branding.v2.gray[50],
      name: 'Custom Order Availability Email',
      subheader: 'Notify customers about custom order availability.',
      route: '/marketing/single-send-email?type=custom-order-available',
      category: 'Basic',
    },
    {
      icon: '👩‍🏫',
      iconBackgroundColor: theme.branding.v2.gray[50],
      name: 'Ticketed Event Announcement Email',
      subheader: 'Drive ticket sales for an upcoming in person or virtual event.',
      route: '/marketing/single-send-email?type=ticketed-event-announcement',
      category: 'Basic',
    },
    ...(isPresalesEnabled
      ? [
          {
            icon: '🛒',
            iconBackgroundColor: theme.branding.v2.gray[50],
            name: 'Presale Announcement Email',
            subheader: 'Notify customers about an active presale.',
            route: '/marketing/single-send-email?type=new-presale',
            category: 'Basic',
          },
        ]
      : []),
    {
      icon: '🎇',
      iconBackgroundColor: theme.branding.v2.red[50],
      pillText: 'Suggested Send: June 8',
      name: '4th of July Announcement Email',
      subheader: 'Introduce your 4th of July offerings.',
      route: '/marketing/single-send-email?type=4th-of-july-announcement',
      event: '4th of July Email Created',
      eventTag: '4thOfJuly',
      category: '4th of July',
    },
    {
      icon: '🎇',
      iconBackgroundColor: theme.branding.v2.red[50],
      pillText: 'Suggested Send: June 11',
      name: '4th of July Customs Email',
      subheader: 'Share about your custom order offerings for 4th of July.',
      route: '/marketing/single-send-email?type=4th-of-july-customs',
      event: '4th of July Email Created',
      eventTag: '4thOfJuly',
      category: '4th of July',
    },
    {
      icon: '🎇',
      iconBackgroundColor: theme.branding.v2.red[50],
      pillText: 'Suggested Send: June 14',
      name: '4th of July Reminder Email',
      subheader: 'Remind customers of your 4th of July offerings.',
      route: '/marketing/single-send-email?type=4th-of-july-reminder',
      event: '4th of July Email Created',
      eventTag: '4thOfJuly',
      category: '4th of July',
    },
    {
      icon: '🎇',
      iconBackgroundColor: theme.branding.v2.red[50],
      pillText: 'Suggested Send: June 21',
      name: '4th of July Engagement Email',
      subheader: 'Engage with customers about your 4th of July offerings.',
      route: '/marketing/single-send-email?type=4th-of-july-engagement',
      event: '4th of July Email Created',
      eventTag: '4thOfJuly',
      category: '4th of July',
    },
    {
      icon: '🎇',
      iconBackgroundColor: theme.branding.v2.red[50],
      pillText: 'Suggested Send: June 28',
      name: '4th of July Shop Email',
      subheader: 'Remind customers of your 4th of July offerings.',
      route: '/marketing/single-send-email?type=4th-of-july-shop',
      event: '4th of July Email Created',
      eventTag: '4thOfJuly',
      category: '4th of July',
    },
    {
      icon: '🎇',
      iconBackgroundColor: theme.branding.v2.red[50],
      pillText: 'Suggested Send: June 30',
      name: '4th of July Last Call Email',
      subheader: 'Engage with customers about your 4th of July offerings.',
      route: '/marketing/single-send-email?type=4th-of-july-last-call',
      event: '4th of July Email Created',
      eventTag: '4thOfJuly',
      category: '4th of July',
    },
    {
      icon: '🎓',
      iconBackgroundColor: theme.branding.v2.lightGreen[50],
      name: 'Graduation Announcement Email',
      subheader: 'Introduce your graduation offerings.',
      route: '/marketing/single-send-email?type=graduation-announcement',
      event: 'Graduation Email Created',
      eventTag: 'Graduation',
      category: 'Graduation',
    },
    {
      icon: '🎓',
      iconBackgroundColor: theme.branding.v2.lightGreen[50],
      name: 'Graduation Reminder Email',
      subheader: 'Remind customers of your graduation offerings.',
      route: '/marketing/single-send-email?type=graduation-reminder',
      event: 'Graduation Email Created',
      eventTag: 'Graduation',
      category: 'Graduation',
    },
    {
      icon: '🎓',
      iconBackgroundColor: theme.branding.v2.lightGreen[50],
      name: 'Graduation Feature Email',
      subheader: 'Highlight your biggest seller for graduation.',
      route: '/marketing/single-send-email?type=graduation-feature',
      event: 'Graduation Email Created',
      eventTag: 'Graduation',
      category: 'Graduation',
    },
    {
      icon: '🎓',
      iconBackgroundColor: theme.branding.v2.lightGreen[50],
      name: 'Graduation Last Call Email',
      subheader: 'Last call for graduation orders!',
      route: '/marketing/single-send-email?type=graduation-last-call',
      event: 'Graduation Email Created',
      eventTag: 'Graduation',
      category: 'Graduation',
    },
    {
      icon: '📚',
      iconBackgroundColor: theme.branding.v2.deepPurple[50],
      name: 'Back-to-School Announcement Email',
      subheader: 'Introduce your back-to-school offerings.',
      route: '/marketing/single-send-email?type=back-to-school-announcement',
      event: 'Back-to-School Email Created',
      eventTag: 'BackToSchool',
      category: 'Back to School',
    },
    {
      icon: '📚',
      iconBackgroundColor: theme.branding.v2.deepPurple[50],
      name: 'Back-to-School Reminder Email',
      subheader: 'Remind customers to shop your back-to-school offerings.',
      route: '/marketing/single-send-email?type=back-to-school-reminder',
      event: 'Back-to-School Email Created',
      eventTag: 'BackToSchool',
      category: 'Back to School',
    },
    {
      icon: '📚',
      iconBackgroundColor: theme.branding.v2.deepPurple[50],
      name: 'Back-to-School Shop Email',
      subheader: 'Promote your back-to-school offerings.',
      route: '/marketing/single-send-email?type=back-to-school-shop',
      event: 'Back-to-School Email Created',
      eventTag: 'BackToSchool',
      category: 'Back to School',
    },
    {
      icon: '📚',
      iconBackgroundColor: theme.branding.v2.deepPurple[50],
      name: 'Back-to-School Custom Order Email',
      subheader: 'Share your back-to-school custom orders.',
      route: '/marketing/single-send-email?type=back-to-school-customs',
      event: 'Back-to-School Email Created',
      eventTag: 'BackToSchool',
      category: 'Back to School',
    },
    {
      icon: '📚',
      iconBackgroundColor: theme.branding.v2.deepPurple[50],
      name: 'Back-to-School Last Call Email',
      subheader: 'Last call for back-to-school orders!',
      route: '/marketing/single-send-email?type=back-to-school-last-call',
      event: 'Back-to-School Email Created',
      eventTag: 'BackToSchool',
      category: 'Back to School',
    },
  ];

  const filteredTemplates = templates.filter(template => {
    if (!template.eventTag || activeEventTags.includes(template.eventTag)) return template;
    else return;
  });

  return filteredTemplates;
};

export const emailCategoriesMap = [
  {
    icon: '📣',
    name: 'Basic',
    description: 'Product announcements, custom orders, and more.',
  },
  {
    icon: '🧢',
    name: "Father's Day",
    description: "Incite customers to interact with your father's day offerings.",
    eventTag: 'FathersDay',
  },
  {
    icon: '🎓',
    name: 'Graduation',
    description: 'Notify customers about graduation offerings.',
    eventTag: 'Graduation',
  },
  {
    icon: '🎇',
    name: '4th of July',
    description: 'Engage customers with all you are offering for this celebration.',
    eventTag: '4thOfJuly',
  },
  {
    icon: '📚',
    name: 'Back to School',
    description: 'Incite customers to interact with your back to school offerings.',
    eventTag: 'BackToSchool',
  },
];
