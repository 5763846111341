import { Theme } from '@material-ui/core';
import { Shop } from '@castiron/domain';

export const getSmsTemplates = async (theme: Theme, isPresalesEnabled: boolean, activeEventTags: string[]) => {
  //in order that they should display in modal
  const templates = [
    {
      icon: '🛒',
      iconBackgroundColor: theme.branding.v2.gray[50],
      name: 'Product Promo Text',
      subheader: 'Drive sales for a specific product.',
      route: '/marketing/single-send-sms?type=product-promo',
      event: 'Product Promo SMS Created',
      category: 'Basic',
    },
    {
      icon: '🤑',
      iconBackgroundColor: theme.branding.v2.gray[50],
      name: 'Coupon Promo Text',
      subheader: 'Drive sales through active coupons and offers.',
      route: '/marketing/single-send-sms?type=coupon-promo',
      event: 'Coupon Promo SMS Created',
      category: 'Basic',
    },
    {
      icon: '👩‍🏫',
      iconBackgroundColor: theme.branding.v2.gray[50],
      name: 'Ticketed Event Promo Text',
      subheader: 'Drive ticket sales for an upcoming in person or virtual event.',
      route: '/marketing/single-send-sms?type=ticketed-event-promo',
      event: 'Ticketed Event Promo SMS Created',
      category: 'Basic',
    },
    {
      icon: '👋',
      iconBackgroundColor: theme.branding.v2.gray[50],
      name: 'Event Promo Text',
      subheader: 'Drive in person interaction and early shopping for an upcoming event.',
      route: '/marketing/single-send-sms?type=event-promo',
      event: 'Event Promo SMS Created',
      category: 'Basic',
    },
    ...(isPresalesEnabled
      ? [
          {
            icon: '🛍️',
            iconBackgroundColor: theme.branding.v2.gray[50],
            name: 'Presale Promo Text',
            subheader: 'Drive sales for an active presale.',
            route: '/marketing/single-send-sms?type=presale-promo',
            event: 'Presale Promo SMS Created',
            category: 'Basic',
          },
        ]
      : []),
    {
      icon: '🎇',
      iconBackgroundColor: theme.branding.v2.red[50],
      name: '4th of July Product Promo Text',
      subheader: 'Drive sales for a specific 4th of July product.',
      route: '/marketing/single-send-sms?type=4th-of-july-promo',
      event: '4th of July Promo SMS Created',
      eventTag: '4thOfJuly',
      category: '4th of July',
    },
    {
      icon: '🎇',
      iconBackgroundColor: theme.branding.v2.red[50],
      name: '4th of July Coupon Promo Text',
      subheader: 'Drive 4th of July sales with an active coupon.',
      route: '/marketing/single-send-sms?type=4th-of-july-coupon-promo',
      event: '4th of July Coupon Promo SMS Created',
      eventTag: '4thOfJuly',
      category: '4th of July',
    },
    {
      icon: '📚',
      iconBackgroundColor: theme.branding.v2.deepPurple[50],
      name: 'Back-to-School Announcement SMS',
      subheader: 'Introduce your back-to-school offerings.',
      route: '/marketing/single-send-sms?type=back-to-school-announcement',
      event: 'Back-to-School SMS Created',
      eventTag: 'BackToSchool',
      category: 'Back to School',
    },
    {
      icon: '📚',
      iconBackgroundColor: theme.branding.v2.deepPurple[50],
      name: 'Back-to-School Reminder SMS',
      subheader: 'Remind customers to shop your back-to-school offerings.',
      route: '/marketing/single-send-sms?type=back-to-school-reminder',
      event: 'Back-to-School SMS Created',
      eventTag: 'BackToSchool',
      category: 'Back to School',
    },
    {
      icon: '📚',
      iconBackgroundColor: theme.branding.v2.deepPurple[50],
      name: 'Back-to-School Coupon SMS',
      subheader: 'Share a coupon to entice customers to shop.',
      route: '/marketing/single-send-sms?type=back-to-school-coupon',
      event: 'Back-to-School SMS Created',
      eventTag: 'BackToSchool',
      category: 'Back to School',
    },
    {
      icon: '📚',
      iconBackgroundColor: theme.branding.v2.deepPurple[50],
      name: 'Back-to-School Shop SMS',
      subheader: 'Promote your back-to-school offerings.',
      route: '/marketing/single-send-sms?type=back-to-school-shop',
      event: 'Back-to-School SMS Created',
      eventTag: 'BackToSchool',
      category: 'Back to School',
    },
    {
      icon: '📚',
      iconBackgroundColor: theme.branding.v2.deepPurple[50],
      name: 'Back-to-School Custom Order SMS',
      subheader: 'Share your back-to-school custom orders.',
      route: '/marketing/single-send-sms?type=back-to-school-customs',
      event: 'Back-to-School SMS Created',
      eventTag: 'BackToSchool',
      category: 'Back to School',
    },
    {
      icon: '📚',
      iconBackgroundColor: theme.branding.v2.deepPurple[50],
      name: 'Back-to-School Last Call SMS',
      subheader: 'Last call for back-to-school orders!',
      route: '/marketing/single-send-sms?type=back-to-school-last-call',
      event: 'Back-to-School SMS Created',
      eventTag: 'BackToSchool',
      category: 'Back to School',
    },
  ];
  const filteredTemplates = templates.filter(template => {
    if (!template.eventTag || activeEventTags.includes(template.eventTag)) return template;
    else return;
  });

  return filteredTemplates;
};

export const smsCategoriesMap = [
  {
    icon: '👋',
    name: 'Basic',
    description: 'Drive immediate engagement for offers, events, and more.',
  },
  {
    icon: '🧢',
    name: "Father's Day",
    description: 'Notify customers Father’s Day offerings.',
    eventTag: 'FathersDay',
  },
  {
    icon: '🎇',
    name: '4th of July',
    description: 'Market 4th of July specific items with ease.',
    eventTag: '4thOfJuly',
  },
  {
    icon: '📚',
    name: 'Back to School',
    description: 'Incite customers to interact with your back to school offerings.',
    eventTag: 'BackToSchool',
  },
];
